/* eslint-disable eqeqeq */
export function setCookie(name, value, daysTillExpiration) {
    var currentDate = new Date();
    currentDate.setTime(
      currentDate.getTime() + daysTillExpiration * 24 * 60 * 60 * 1000
    );
    var expires = "expires=" + currentDate.toUTCString();
    if (name == "cart")
    {
      let cart = getCookie(name)
      if(cart)
      {
        document.cookie = name + "=" + [cart,value] + ";" + expires + ";path=/";
      }
      else{
        document.cookie = name + "=" + [value].toString() + ";" + expires + ";path=/";
      }
    }
    else{
      if (name == "shipping")
      {
        document.cookie = name + "=" + [value].toString() + ";" + expires + ";path=/";
      }
      else{
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
      }
    }
    
    if (name == "token")
    {
      document.cookie = "stmtoken=" + value + ";" + expires + ";path=/;domain=strongerthanmed.azurewebsites.net";
    }
  }

  export function updateCartCookie(name, value) {
    var currentDate = new Date();
    currentDate.setTime(
      currentDate.getTime() + 1 * 24 * 60 * 60 * 1000
    );
    var expires = "expires=" + currentDate.toUTCString();
    document.cookie = name + "=" + [value].toString() + ";" + expires + ";path=/";
  }
  
  export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  
    return JSON.parse(jsonPayload);
  }
  