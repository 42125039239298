import * as actionTypes from './actionTypes';
import { getCookie } from '../Utils/Cookies';


function getCart() {
    let cart = getCookie("cart");
    if (cart)
    {
        try {
            let attempt = JSON.parse("["+cart+"]");
            return attempt.length;
        }
        catch (error)
        {
            return 0;
        }
        
    }
    return 0;
}

export const initialState = {
    cartCount: (typeof window !== "undefined") ? getCart() : 0,
    update_count: (count) => { console.log("function")}
}

export const ContextReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_COUNT:
            return {
                ...state,
                cartCount: action.payload
            }
        default:
            throw new Error();
    }
}
        