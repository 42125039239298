
import React, { useReducer, useEffect } from "react"
import * as ContextReducer from "./reducer/context";
import * as actionTypes from "./reducer/actionTypes";
import Context from "./Utils/context"


export default function RootLayout({ children }) {
    const [contextState, contextDispatch] = useReducer(ContextReducer.ContextReducer,
        ContextReducer.initialState);
    return (
      <>
    <Context.Provider value={{
      ...contextState,
      update_count: (count) => contextDispatch({type: actionTypes.UPDATE_COUNT, payload: count}),
        }}>
              {children}
              </Context.Provider>
      </>
    );
  }